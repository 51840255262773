import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { TOKEN_EXPIRED } from "../ducks/user";
import { bancariaAPI } from "../utils/bancariaApi";

export default function AxiosInterceptor({ children }) {
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user);

  useEffect(() => {
    let interceptor;
    if (user.isAuthenticated) {
      interceptor = bancariaAPI.interceptors.response.use(
        (response) => {
          if (response === undefined && user.isAuthenticated) {
            dispatch({ type: TOKEN_EXPIRED });
            localStorage.removeItem("isAuthenticated");
            localStorage.removeItem("isAdmin");
          } else {
            return response;
          }
        },
        (error) => {
          if (error?.response?.status === 401 && user.isAuthenticated) {
            dispatch({ type: TOKEN_EXPIRED });
            localStorage.removeItem("isAuthenticated");
            localStorage.removeItem("isAdmin");
          } else {
            return Promise.reject(error);
          }
        }
      );
    }
    return () => {
      bancariaAPI.interceptors.response.eject(interceptor);
    };
  }, [user.isAuthenticated, dispatch]);
  return <>{children}</>;
}
