import React from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import ReCAPTCHA from "react-google-recaptcha";
import { Link as RouterLink } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import TextHelperError from "./Global/TextHelperError";
import { bancariaAPI } from "../utils/bancariaApi";
import { useState } from "react";

import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import HelpLink from "./HelpLink";


const useStyles = makeStyles((theme) => ({
  container: {
    height: "100vh",
    backgroundColor: "#f4f8ff",
  },
  containerGrid: {
    height: "100vh",
  },
  fullWidth: {
    width: "100%",
  },
}));

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Por favor ingrese un email correcto")
    .required("Por favor ingresar email"),
  password: Yup.string().required("Por favor ingresar contraseña"),
  recaptcha: Yup.string().required("Debe validar el reCATPCHA"),
});

const LinkRecover = React.forwardRef((props, ref) => (
  <RouterLink ref={ref} to="/recover" {...props} />
));
const recaptchaRef = React.createRef();

export default function LoginForm() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Container className={classes.container}>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.containerGrid}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{ email: "", password: "", recaptcha: "" }}
            validationSchema={LoginSchema}
            onSubmit={(values, actions) => {
              bancariaAPI
                .post("/usuario/ingresar", values)
                .then(({ data }) => {
                  localStorage.setItem("isAuthenticated", true);
                  localStorage.setItem("isAdmin", data.admin);
                  actions.setSubmitting(false);
                  dispatch({ type: "LOGIN_USER", payload: data });
                })
                .catch((err) => {
                  window.grecaptcha.reset();
                  if (err?.response?.data?.message) {
                    actions.setFieldError(
                      "general",
                      err?.response?.data?.message
                    );
                  } else if (err) {
                    actions.setFieldError(
                      "general",
                      "Lo sentimos, ocurrió un error al querer ingresar. Intente mas tarde nuevamente"
                    );
                  } else {
                    actions.setFieldError(
                      "general",
                      "Lo sentimos, ocurrió un error al querer ingresar. Intente mas tarde nuevamente"
                    );
                  }
                  actions.setSubmitting(false);
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              isSubmitting,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <form method="POST" onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h3">Bienvenido</Typography>
                  </Grid>

                  <Grid item xs={12} style={{ marginTop: "4rem" }}>
                    <FormControl
                      variant="outlined"
                      className={classes.fullWidth}
                    >
                      <InputLabel htmlFor="email">Email</InputLabel>
                      <OutlinedInput
                        id="email"
                        value={values.email}
                        onChange={handleChange}
                        label="email"
                        name="email"
                      />
                      {errors.email && touched.email ? (
                        <TextHelperError>{errors.email}</TextHelperError>
                      ) : null}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      variant="outlined"
                      className={classes.fullWidth}
                    >
                      <InputLabel htmlFor="password">Contraseña</InputLabel>
                      <OutlinedInput
                        id="password"
                        value={values.password}
                        onChange={handleChange}
                        label="password"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {values.showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />

                      {errors.password && touched.password ? (
                        <TextHelperError>{errors.password}</TextHelperError>
                      ) : null}
                    </FormControl>
                  </Grid>
                  {errors.general && (
                    <Grid item xs={12}>
                      <Alert severity="error">{errors.general}</Alert>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={process.env.REACT_APP_CAPTCHA_KEY}
                        onChange={(response) => {
                          setFieldValue("recaptcha", response);
                        }}
                        onExpired={(response) =>
                          setFieldValue("recaptcha", response)
                        }
                      />
                      {errors.recaptcha && touched.recaptcha && (
                        <TextHelperError>{errors.recaptcha}</TextHelperError>
                      )}
                    </Grid>
                    <Button
                      variant="contained"
                      type="submit"
                      color="primary"
                      className={classes.fullWidth}
                      disabled={isSubmitting}
                      style={{ marginTop: "1rem" }}
                    >
                      Ingresar
                    </Button>
                  </Grid>
                  <Grid item xs={12} container justify="center">
                    <Link component={LinkRecover}>Recuperar Contraseña</Link>
                  </Grid>
                  <Grid item xs={12} container justify="center">
                    <HelpLink />
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Container>
      </Grid>
    </Container>
  );
}
