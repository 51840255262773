import Link from "@material-ui/core/Link";

export default function HelpLink() {
  return (
    <Link
      href={`${process.env.REACT_APP_DOMAIN}/static/manual-usuario.pdf`}
      target="_blank"
      rel="noreferrer"
    >
      ¿Necesita ayuda?
    </Link>
  );
}
