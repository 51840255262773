import React, { Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import green from "@material-ui/core/colors/green";
import blue from "@material-ui/core/colors/blue";

import PrivateRoute from "./components/PrivateRouter";

import Login from "./pages/Login";
import Loading from "./components/Loading";

import FloatingHelp from "./components/FloatingHelp";


const Page404 = React.lazy(() => import("./pages/Page404."));
const Recover = React.lazy(() => import("./pages/ForgotPassword"));
const Admin = React.lazy(() => import("./pages/Admin.js"));
const Home = React.lazy(() => import("./pages/Home"));
const Setting = React.lazy(() => import("./pages/Setting"));
const CloseSession = React.lazy(() => import("./pages/CloseSession"));
const RestorePasswordPage = React.lazy(() => import("./pages/RestorePassword"));

const theme = createMuiTheme({
  palette: {
    primary: {
      main: blue[500],
    },
    secondary: {
      main: green[300],
    },
  },
});

export default function Routes() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route exact path="/">
              <Login />
            </Route>
            <Route path="/recover">
              <Recover />
            </Route>
            <Route path="/restorePassword">
              <RestorePasswordPage />
            </Route>
            <PrivateRoute path="/home">
              <Home />
            </PrivateRoute>
            <PrivateRoute path="/admin">
              <Admin />
            </PrivateRoute>
            <PrivateRoute path="/setting">
              <Setting />
            </PrivateRoute>
            <PrivateRoute path="/closeSession">
              <CloseSession />
            </PrivateRoute>
            <Route path="*">
              <Page404 />
            </Route>
          </Switch>

          <FloatingHelp />

        </Suspense>
      </Router>
    </ThemeProvider>
  );
}
