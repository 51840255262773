import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import LoginForm from "../components/LoginForm";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  logo: {
    paddingTop: "5px",
    paddingBottom: "5px",
    maxWidth: "50%",
    width: "400px",
  },
  container: {
    minHeight: "100vh",
  },
}));

const Login = () => {
  const classes = useStyles();
  const { isAuthenticated, tokenExpired } = useSelector((store) => store.user);
  const [open, setOpen] = useState(true);
  if (isAuthenticated) {
    return <Redirect to="/home" />;
  }

  return (
    <>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.container}
      >
        <Grid item xs={12} md={6} lg={8} container justify="center">
          <img
            src={`${process.env.REACT_APP_DOMAIN}/static/image/logo.jpg`}
            alt="La Bancaria"
            className={classes.logo}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <LoginForm />
        </Grid>
      </Grid>
      {tokenExpired && (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={() => {
            setOpen(false);
          }}
        >
          <Alert severity="info">La sesión ha finalizado</Alert>
        </Snackbar>
      )}
    </>
  );
};

export default Login;
