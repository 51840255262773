import React from "react";
import { Provider } from "react-redux";
import AxiosInterceptor from "./Layout/AxiosInterceptor";
import Routes from "./routes";
import store from "./store";

const App = () => {
  return (
    <Provider store={store}>
      <AxiosInterceptor>
        <Routes />
      </AxiosInterceptor>
    </Provider>
  );
};

export default App;
