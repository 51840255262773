const initState = {
  navbarName: "Inicio",
};

export default function reducer(state = initState, action = {}) {
  switch (action.type) {
    case "CHANGE_NAVBAR_NAME": {
      return { ...state, navbarName: action.payload };
    }
    default:
      return state;
  }
}
