import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";
import red from "@material-ui/core/colors/red";

const useStyle = makeStyles((theme) => ({
  errorStyle: {
    paddingTop: theme.spacing(1),
    color: red[600],
  },
}));
export default function TextHelperError({ children }) {
  const classes = useStyle();
  return (
    <Typography variant="caption" className={classes.errorStyle}>
      {children}
    </Typography>
  );
}
