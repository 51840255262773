import { createStore } from "redux";
import appReducer from "./ducks";

function userState() {
  const isAuthenticated = !!localStorage.getItem("isAuthenticated");
  const admin = localStorage.getItem("isAdmin") === "true";
  return {
    user: {
      isAuthenticated,
      admin,
    },
  };
}

export default createStore(
  appReducer,
  userState(),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
