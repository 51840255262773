import Fab from "@material-ui/core/Fab";
import { makeStyles } from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: 50,
    right: 50,
    padding: 0,
  },
}));

export default function FloatingHelp() {
  const classes = useStyles();

  return (
    <Fab
      className={classes.fab}
      color="primary"
      label="help"
      href={`${process.env.REACT_APP_DOMAIN}/static/manual-usuario.pdf`}
      target="_blank"
      rel="noreferrer"
    >
      <HelpOutlineIcon fontSize="large" />
    </Fab>
  );
}
