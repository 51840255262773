import { CircularProgress, Container, Grid } from "@material-ui/core";

export default function Loading() {
  return (
    <Container style={{ marginTop: "2rem" }}>
      <Grid container justify="center">
        <CircularProgress />
      </Grid>
    </Container>
  );
}
