const initState = {
  isAuthenticated: false,
  tokenExpired: false,
  admin: false,
};

export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const TOKEN_EXPIRED = "TOKEN EXPIRED";

export function userReducer(state = initState, action = {}) {
  switch (action.type) {
    case LOGIN_USER:
      return {
        isAuthenticated: true,
        admin: action.payload.admin,
        tokenExpired: false,
      };
    case LOGOUT_USER:
      return { isAuthenticated: false };
    case TOKEN_EXPIRED:
      return { isAuthenticated: false, tokenExpired: true };
    default:
      return state;
  }
}
